import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Career from '../images/career.svg';
import Coding from '../images/coding.svg';
import Hobbies from '../images/hobbies.svg';
import SimpleCard from './card';
import Fade from '@material-ui/core/Fade';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      textAlign: 'center',
      padding: theme.spacing(5, 5, 5),
      color: '#555555',
    },

  }));

function AboutSection() {
    const classes = useStyles();
    ;
    return (

        <div className={classes.root}>
        <Fade in={true} timeout={2500}>
        <Typography><h1 style={{marginBottom: 50}}>About me</h1></Typography></Fade>
        <Fade in={true} timeout={2500}><Grid container spacing={7}>
      <Grid item xs>
<SimpleCard
  aboutImage={Career}
  aboutTitle={'Career'}
  aboutContent1={'Started out as a Peace Corps Volunteer'}
  aboutContent2={'Worked at both large companies and start ups'}
  aboutContent3={'Currently working for a financial services institution'}
  aboutAction={'Check out my work history on LinkedIn'}
  aboutActionLink={'https://www.linkedin.com/in/martinsterlicchi/'}
/>
</Grid>
<Grid item xs>
<SimpleCard
  aboutImage={Coding}
  aboutTitle={'Coding'}
  aboutContent1={'Began by validating blockchain transactions'}
  aboutContent2={'Created web projects mainly using React'}
  aboutContent3={'Currently focusing on Ethereum smart contracts'}
  aboutAction={'Check out my public projects on GitHub'}
  aboutActionLink={'https://github.com/AboldUSER/'}
/>
</Grid>
<Grid item xs>
<SimpleCard
  aboutImage={Hobbies}
  aboutTitle={'Hobbies'}
  aboutContent1={'Have played drums since high school jazz band'}
  aboutContent2={'Loves books/movies/games about SciFi & horror'}
  aboutContent3={'Will hit up the local rock climbing gym'}
  aboutAction={'Check out my infrequent posts on Twitter'}
  aboutActionLink={'https://twitter.com/Martin_Lastname'}
/>
</Grid>
      </Grid>
      </Fade>
      </div>

    )
}

export default AboutSection;