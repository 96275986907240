import React from 'react';
import HomeSection from './homesection'
import AboutSection from './aboutsection'
import Footer from './footer'
import Fade from '@material-ui/core/Fade';

function HomePage() {
return (
    <div style={{ fontFamily:'montserrat'}}>
    <Fade in={true} timeout={1000} disableStrictModeCompat={true}>
<HomeSection />
</Fade>
<AboutSection />
<Footer />

</div>
);
}

export default HomePage;