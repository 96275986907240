import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardMedia, Button } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    color: '#363533',
    backgroundColor: '#d9e4dd',
    height: "100%",
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  media: {
    margin: 'auto',
    padding: theme.spacing(2, 0, 0),
    width: 100
  },
  title: {
    fontSize: 30,
    fontFamily:'montserrat',
  },
  pos: {
    fontSize: 15,
    marginTop: 10,
    marginBottom: 12,
    fontFamily:'montserrat',
  },
  button: {
    justifyContent: 'center',
    flex: '1 auto auto',
    backgroundColor:'#fbf7f0',
  },
}));

export default function SimpleCard(props) {
  
  const classes = useStyles();

  return (
    <Card className={classes.root}>
    <CardMedia><img className={classes.media} src={props.aboutImage} alt="About Icon" /></CardMedia>
      <CardContent>
        <Typography className={classes.title}>
         {props.aboutTitle}
        </Typography>
        <Typography className={classes.pos} variant="body2">
        {props.aboutContent1}
        </Typography>
        <Typography className={classes.pos} variant="body2">
        {props.aboutContent2}
        </Typography>
        <Typography className={classes.pos} variant="body2">
        {props.aboutContent3}
        </Typography>
      </CardContent>
      <CardActions className={classes.button}>
        <Button style={{fontFamily:'montserrat'}} size="small" onClick={() => { window.open(props.aboutActionLink) }}>{props.aboutAction}</Button>
      </CardActions>
    </Card>
  );
}