import React from 'react';
import './App.css';
import HomePage from './components/homepage'
// import Fade from '@material-ui/core/Fade';

function App() {
  return (
    // <Fade in={true} timeout={5000} >
      <HomePage />
    // </Fade>
  );
}

export default App;
