import React from 'react';
import { makeStyles } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      textAlign: 'center',
      padding: theme.spacing(2, 2, 2),
      backgroundColor: '#cdc9c3',
      color: '#555555',
    }
  }));

function Footer() {
    const classes = useStyles();
    const year = new Date().getFullYear();
    return (
        
      <Fade in={true} timeout={3000}>
        <div className={classes.root}>
      <p>Made with React - Hosted on IPFS - Updated {year}</p>
      </div>
      </Fade>

    )
}

export default Footer;