import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import bold from '../images/me.png'
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      textAlign: 'center',
      padding: theme.spacing(5, 3, 0),
      color: '#555555'
    },
    image: {
      borderRadius: 30,
      width: '60%',
      minWidth: '300px',
      boxShadow: '0 0 15px #A3A5AE',
    }
  }));

function HomeSection() {
    const classes = useStyles();
    return (

        <div className={classes.root}>
      <Grid container spacing={5}>
      <Fade in={true} timeout={2000}>
        <Grid style={{margin: "auto", maxWidth: '700px'}} item md>
          <h1>Hi there 👋</h1>
          <h3>This is my personal site to give you some info on who I am and how to connect</h3>        
        </Grid>
        </Fade>
        <Fade in={true} timeout={3500}>
        <Grid item md>
          <img src={bold} className={classes.image} alt="Martin" transitionTime="3s" />
        </Grid>
        </Fade>
      </Grid>
      </div>

    )
}

export default HomeSection;